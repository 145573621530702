$max: 1440;
$ctr: var(--container-value);
$xl: 1200;
$lg: 992;
$md: 768;
$sm: 600;
$xs: 480;

@mixin rmin($width) {
  @media (min-width: #{$width}px) {
    @content;
  }
}

@mixin rhmin($width) {
  @media (min-height: #{$width}px) {
    @content;
  }
}

@mixin rhmax($width) {
  @media (max-height: #{$width}px) {
    @content;
  }
}

@mixin rmax($width) {
  @media (max-width: #{$width}px) {
    @content;
  }
}

@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }

  }
}

@function sizeMax($max, $min, $wmax: 1440, $wmin: 360) {
  $size: calc(#{$min}px + (#{$max} - #{$min}) * ((100vw - #{$wmin}px) / (#{$wmax} - #{$wmin})));
  @return $size;
}

@mixin size($propName, $max, $min, $stopSize: 1440) {
  @if $max == $min {
    #{$propName}: #{$max}px;
  } @else {
    #{$propName}: sizeMax($max, $min);

    @include rmin($stopSize) {
      #{$propName}: $max;
    }
  }
}