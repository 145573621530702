@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.our-farmers {

  .main-title {
    @include size('font-size', 46, 30);
  }

  .main-text {
    font-weight: 800;
    max-width: 1000px;
    margin-inline: auto;
    letter-spacing: -0.03em;
    line-height: 1.35;
  }

  .inner {
    max-width: 1107px;
    margin: 0 auto;
    gap: 62px 38px;

    @include rmax($lg) {
      gap: 30px;
    }
  }

  .card {
    color: rgba(#fff, 0.65);

    &__title {
      line-height: 1.35;
    }

    &__text {
      line-height: 1.4;
    }

    &__image {
      @include rmax($xl) {
        width: 100%;
        height: 250px;
      }
    }
  }
}