.num-after,
.num-before {
  &[data-number],
  & [data-number] {
    position: relative;

    &::before,
    &::after {
      display: block;
      color: var(--c-black)
    }
  }
}

.num-after {
  &[data-number],
  & [data-number] {
    &::after {
      content: attr(data-number);
    }
  }
}

.num-before {
  &[data-number],
  & [data-number] {
    &::before {
      content: attr(data-number);
    }
  }
}

.num-accent {
  &[data-number],
  & [data-number] {
    &::before,
    &::after {
      color: var(--c-accent);
    }
  }
}