@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;



$makeResponsiveBreakpoint: $xl;
$navResponsiveBg: #E0EBD2;
$topPos: 100px;

.nav {
  border-radius: 10px;
  background-color: #E0EBD2;
  padding: 22px 40px;

  @include rmax($sm) {
    justify-content: space-between;
    padding: 22px 20px;
  }
  &.opened {
    box-shadow: unset !important;
    .nav__inner {
      transform: translateY(0);
      transition: transform 0.2s ease-in-out;
    }
  }

  &__inner {
    @include rmax($makeResponsiveBreakpoint) {
      padding: 45px 0 30px;
      position: absolute;
      left: 15px;
      right: 15px;
      width: calc(100% - 30px);
      top: calc($topPos - 25px);
      flex-direction: column;
      background-color: $navResponsiveBg;
      border-radius: 0 0 20px 20px;
      transform: translateY(-200%);
      overflow-y: auto;
      z-index: -1;
    }

    @include rhmax(600) {
      height: calc(100svh - $topPos);
    }
  }

}