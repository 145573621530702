@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@import '../base/global-vars';

$rLG: $radius-lg;
$rMD: $radius-md;
$rSM: $radius-sm;

.radius {
  &-lg {
    border-radius: var(--radius-lg);
    @include size('border-radius', $rLG, 20);
  }

  &-md {
    @include size('border-radius', $rMD, 16);
  }

  &-sm {
    @include size('border-radius', $rSM, $rSM);
  }

  &-4 {
    @include size('border-radius', 40, 20);
  }

  &-3 {
    @include size('border-radius', 30, 15);
  }

  &-2 {
    @include size('border-radius', 20, 10);
  }

  &-1 {
    border-radius: 10px;
  }

  &-circle {
    border-radius: 50%;
  }

}