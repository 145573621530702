.dot-before,
.dot-after {
  position: relative;
  &::before,
  &::after {
    display: block;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: var(--c-black);
  }
}

.dot-after {
  &::after {
    content: '';
  }
}

.dot-before {
    &::before {
      content: '';
    }
}

.dot {
  &-accent {
    &::before,
    &::after {
      background-color: var(--c-accent);
    }
  }
}

.dot {
  &-lg {
    &::before,
    &::after {
      width: 6px;
      height: 6px;
    }
  }
}