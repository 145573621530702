@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.some-new {

  .container {
    max-width: 1130px;
  }

  .main-title {
    font-size: 26px;
    line-height: 1;
  }

  .list {
    gap: 50px;
    @include rmax($lg) {
      gap: 20px;
    }
  }

  .card {


    &__date {
      font-size: 20px;
      font-weight: 800;
      line-height: 1.35;
    }

    &__image {
      @include rmax($lg) {
        height: 250px;
        width: 100%;
      }
    }
  }
}