.ls {
  &-02 {
    letter-spacing: 0.02em;
  }
  &-01 {
    letter-spacing: 0.01em;
  }
}

.-ls {
  &-02 {
    letter-spacing: -0.02em;
  }
  &-01 {
    letter-spacing: -0.01em;
  }
}