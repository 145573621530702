/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1200px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-value: 1230;

  // TODO: формула для розрахунку колонок
  // --col-width: calc(100% / 12);
  // --col-gap: 24px;

  --header-indents: 29px;
  --main-icon-size: 40px;

  // fonts
  --font-family-primary: "Gilroy", sans-serif;
  --font-family-secondary: "Noto Sans", sans-serif;

  // line-height
  --lineHeight: 1.26;
  --letterSpacing: 0;

  // font-weight
  --fw-400: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;

  // main-font-size
  --fz-text-normal: 20px;

  // colors
  --c-white:  #ffffff;
  --c-black:  #161C2D;

  --c-primary: #4f6a5a;
  --c-secondary: #343434;

  --c-light: #F8FFEF; // light-green
  --c-dark: #1F322B; // dark-green
  --c-gray: #ededed;

  --c-accent: #508F00;


  --c-green: #A3D16C;
  --c-pink: #FF007A;

  //gradients
  --main-gradient: linear-gradient(0.13deg, rgba(#ffc61b, 0.4) 0.11%, rgba(#fcf5df, 0.4) 111.96%);
  --dark-gradient: linear-gradient(270deg, rgba(0, 0, 0, 0.0001) 0%, #161C2D 99.54%);

}