
@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.product-one-page {

  .main-top {
    padding-top: 120px;
  }

  .product-section {

    .container {
      max-width: 1274px;
    }

    .inner {
      align-items: flex-start;
      @include rmax($xl) {
        justify-content: flex-start;
      }
      gap: 20px;

      @include rmax($md) {
        flex-direction: column;
      }
    }

    .product-image {
      @include rmax($xl) {
        max-width: 400px;
      }
      @include rmax($lg) {
        max-width: 300px;
      }

      @include rmax($md) {
        max-width: 100%;
        width: 100%;
        height: 300px;
        object-fit: cover;
      }
    }

    .product-card {
      max-width: 610px;
      @include rmax($xl) {
        max-width: 100%;
      }
      font-size: 18px;
    }

    .back-link {
      display: flex;
      gap: 5px;
      @include size('margin-bottom', 24, 15);

      &::before {
        width: 24px;
        height: 24px;
      }
    }

    .main-title {
      @include size('font-size', 50, 30);
      @include size('margin-bottom', 28, 15);
    }

    .main-text {
      @include size('margin-bottom', 24, 15);
    }

    .product-details {
      @include size('margin-bottom', 24, 15);
    }

    .title {
      @include size('margin-bottom', 24, 15);
      color: var(--c-accent);
      @include size('font-size', 32, 24);
    }

    .details-list {
      @include size('margin-bottom', 24, 15);

      dt {
        white-space: nowrap;
        color: var(--c-accent)
      }

      dd {
        white-space: nowrap;
        @include rmax($md) {
          white-space: wrap;
        }
      }
    }

    .details-group {
      display: flex;
      gap: 5px;
      &:not(:last-of-type) {
        @include size('margin-bottom', 30, 15);
      }
      @include rmax($xl) {
        flex-wrap: wrap;
      }


    }

    .price {
      font-size: 38px;
      color: var(--c-accent)
    }
  }
}