@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@import '../base/global-vars';

$fzXXL: $title-xxl;
$fzXL: $title-xl;
$fzLG: $title-lg;
$fzMD: $title-md;
$fzSM: $title-sm;
$fzXS: $title-xs;
$fzXXS: $title-xxs;

.title {

  &-xxl {
    @include size('font-size', $fzXXL, 56);
  }

  &-xl {
    @include size('font-size', $fzXL, 48);
  }

  &-lg {
    @include size('font-size', $fzLG, 36);
  }

  &-md {
    @include size('font-size', $fzMD, 24);
  }

  &-sm {
    font-size: #{$fzSM}px;
    @include rmax($sm) {
      font-size: 20px;
    }
  }

  &-xs {
    font-size: #{$fzXS}px;
    @include rmax($sm) {
      font-size: 18px;
    }
  }

  &-xxs {
    font-size: #{$fzXXS}px;
  }
}