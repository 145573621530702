@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.practices {

  .dots {
    &::before {
      content: '';
      width: 314px;
      height: 8px;
      margin-bottom: 40px;
    }
  }

  .inner {
    @include size('padding-top', 80, 30);
    gap: 30px;
    @include size('margin-bottom', 75, 30);

    @include rmax($lg) {
      flex-wrap: wrap
    }
  }

  .main-title {
    @include size('font-size', 52, 24);
  }

  .text {
    background-color: var(--c-green);
    @include size('font-size', 32, 24);
  }

  .list {

    & > li {
      &:not(:last-child) {
        @include size('margin-bottom', 86, 30);
      }
    }
  }

  .card {
    &__title {
      font-weight: 300;
    }

    &__images {
      @include size('gap', 43, 20);
      @include rmax($xl) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      @include rmax($sm) {
        grid-template-columns: 1fr;
      }
    }

    &__image {
      @include rmax($xl) {
        width: 100%;
        height: 250px;
      }
    }

    &__list {
      font-family: var(--font-family-secondary);
      padding-left: 10px;
      font-weight: 500;
      li {
        list-style-position: inside;
        list-style-type: disc;
      }
    }

    &.card-reversed {

      .card__title {
        @include rmax($xl) {
          text-align: left;
        }

      }
      .card__list {
        margin-left: auto;
        max-width: 720px;
        @include rmax($xl) {
          margin-left: 0;
          max-width: 100%;
        }
      }
    }
  }
}