@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.our-story {
  .main-title {

    @include size('font-size', 46, 30);
  }

  .card {
    &__title {
      width: 100%;
      padding: 44px 15px;
      background-color: #A3D16C;
      border-radius: 20px;
      margin-bottom: 20px;
      @include rmax($md) {
        padding: 15px 15px;
        border-radius: 10px;
      }
    }

    &__text {
      font-weight: 800;
    }
  }
}