@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
@import "../base/global-vars";

.gap {

  &-xxl {
    @include size('gap', $indent-xxl, 20);
  }

  &-xl {
    @include size('gap', $indent-xl, 20);
  }

  &-lg {
    @include size('gap', $indent-lg, 20);
  }

  &-md {
    @include size('gap', $indent-md, 20);
  }

  &-sm {
    gap: #{$indent-sm}px;
    @include rmax($md) {
      gap: 16px;
    }
  }

  &-xs {
    gap: #{$indent-xs}px;
  }

  &-xxs {
    gap: #{$indent-xxs}px;
  }

  &-6 {
    @include size('gap', 60, 20);
  }

  &-5 {
    @include size('gap', 50, 20);
  }

  &-4 {
    @include size('gap', 40, 20);
  }

  &-3 {
    @include size('gap', 30, 15);
  }

  &-2 {
    gap: 20px;
  }


  &-1 {
    gap: 10px;
  }

}

.c-gap {
  &-xxl {
    @include size('column-gap', $indent-xxl, 20);
  }

  &-xl {
    @include size('column-gap', $indent-xl, 20);
  }

  &-lg {
    @include size('column-gap', $indent-lg, 20);
  }

  &-md {
    @include size('column-gap', $indent-md, 20);
  }

  &-sm {
    column-gap: #{$indent-sm}px;
    @include rmax($md) {
      column-gap: 16px;
    }
  }

  &-xs {
    column-gap: #{$indent-xs}px;
  }

  &-xxs {
    column-gap: #{$indent-xxs}px;
  }

  &-6 {
    @include size('column-gap', 60, 20);
  }

  &-5 {
    @include size('column-gap', 50, 20);
  }

  &-4 {
    @include size('column-gap', 40, 20);
  }

  &-3 {
    @include size('column-gap', 30, 15);
  }

  &-2 {
    column-gap: 20px;
  }


  &-1 {
    column-gap: 10px;
  }
}

.r-gap {
  &-xxl {
    @include size('row-gap', $indent-xxl, 20);
  }

  &-xl {
    @include size('row-gap', $indent-xl, 20);
  }

  &-lg {
    @include size('row-gap', $indent-lg, 20);
  }

  &-md {
    @include size('row-gap', $indent-md, 20);
  }

  &-sm {
    row-gap: #{$indent-sm}px;
    @include rmax($md) {
      row-gap: 16px;
    }
  }

  &-xs {
    row-gap: #{$indent-xs}px;
  }

  &-xxs {
    row-gap: #{$indent-xxs}px;
  }

  &-6 {
    @include size('row-gap', 60, 20);
  }

  &-5 {
    @include size('row-gap', 50, 20);
  }

  &-4 {
    @include size('row-gap', 40, 20);
  }

  &-3 {
    @include size('row-gap', 30, 15);
  }

  &-2 {
    row-gap: 20px;
  }


  &-1 {
    row-gap: 10px;
  }
}
