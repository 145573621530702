.bg-primary {
  background-color: var(--c-primary);
}

.bg-secondary {
  background-color: var(--c-secondary);
}

.bg-light {
  background-color: var(--c-light);
}

.bg-dark {
  background-color: var(--c-dark);
}

.bg-accent {
  background-color: var(--c-accent);
}

.bg-white {
  background-color: var(--c-white);
}

.bg-gray {
  background-color: var(--c-gray);
}

.bg-nr {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-center {
  background-position: center;
}