@use "./../../../scss/utils/functions/"as*;
@use "./../../../scss/utils/mixins/"as*;


.policy {
  @include size('padding-top', 166, 90);

  .container {
    max-width: 1150px;
  }

  h1 {
    text-align: center;
    @include size('margin-bottom', 60, 30);
    font-weight: 500;
    @include size('font-size', 40, 30)
  }


  &__content {
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 48px;

    h2 {
      font-size: 28px;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 20px;
    }
  }

  a {
    font-weight: 600;
    color: var(--c-black);
  }
}