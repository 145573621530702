@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.about-hero {

  .hero__inner {
    padding-top: 40px;
  }

  .hero__content {
    max-width: 800px;
  }

  .text-pink {
    letter-spacing: 0.125em;
  }

  .hero__title {
    letter-spacing: -0.03em;
    margin-bottom: 18px;
  }

  .hero__text {
    letter-spacing: 0.02em;
  }
}