@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.logo {
  display: block;
  width: fit-content;
  outline: 2px solid transparent;
  transition: 0.3s ease-in-out;

  &:focus {
    outline-color: rgba(#000, 0.5);
    outline-offset: 10px;
  }

  @include rmax($xs) {
    max-width: 200px;
  }
}
