@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.d-grid {
  display: grid;
}

.g-col {
  &-1 {
    grid-template-columns: 1fr;
  }

  &-2 {
    grid-template-columns: repeat(2, 1fr);


    &.g-col-1-lg {
      @include rmax($lg) {
        grid-template-columns: 1fr;
      }
    }

    &.g-col-1-md {
      @include rmax($md) {
        grid-template-columns: 1fr;
      }
    }

    &.g-col-1-sm {
        @include rmax($sm) {
          grid-template-columns: 1fr;
        }
    }
  }

  &-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &-4 {
    grid-template-columns: repeat(4, 1fr);
    &.g-col-2-lg {
      @include rmax($lg) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.g-col-2-md {
      @include rmax($md) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.g-col-2-sm {
        @include rmax($sm) {
          grid-template-columns: repeat(2, 1fr);
        }
    }

    &.g-col-1-md {
      @include rmax($md) {
        grid-template-columns: 1fr;
      }
    }
    &.g-col-1-sm {
      @include rmax($sm) {
        grid-template-columns: 1fr;
      }
    }
  }
}
