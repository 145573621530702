@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.footer {
  color: var(--c-white);
  background-color: var(--c-black);
  @include size('padding-top', 85, 20);
  @include size('padding-bottom', 85, 20);

  .container {
    max-width: 1310px;
  }

  .copy {
    font-size: 22px;
  }

  &__menu {
    @include size('gap', 60, 20);
    font-weight: 800;
  }
}
