@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.catalog {
  @include size('padding-top', 96, 30);

  .container {
    max-width: 1372px;
  }

  .main-title {
    @include size('font-size', 46, 30);
    @include size('margin-bottom', 65, 30);
  }

  .tabs__nav {
    max-width: 820px;
    margin-inline: auto;
    gap: 30px 36px;

    line-height: 1.1;
    letter-spacing: 0.02em;
    @include rmax($sm) {
      gap: 16px;
    }
  }

  .tabs__link {
    padding: 4px 8px;
    font-weight: 800;
    font-size: 26px;
    white-space: nowrap;
    @include rmax($sm) {
      font-size: 18px;
    }

    &.active {
      color: var(--c-accent)
    }
  }

  .tabs__content {
    & > .flex {
      justify-content: space-between;
      column-gap: 46px;
      @include rmax($xl) {
        column-gap: 15px;
      }
      @include size('row-gap', 79, 20);
    }

    @include rmax($md) {
      max-width: 450px;
      margin-inline: auto;
    }
  }

  .card {

    &__link {
      align-items: flex-start;
    }
    img {
      aspect-ratio: 1 / 1;
      @include size('width', 200, 150);
    }

    &__title {
      font-size: 28px;
      line-height: 1;
      margin-bottom: 16px;
      @include rmax($sm) {
        margin-bottom: 8px;
      }
    }

    &__description {
      margin-bottom: 21px;
      @include rmax($sm) {
        margin-bottom: 10px;
      }
    }

    .price {
      display: block;
      font-weight: 800;
      @include size('font-size', 38, 20);
      letter-spacing: 0.02em;
      line-height: 1.1;
      margin-bottom: 24px;
      white-space: nowrap;
      @include rmax($sm) {
        margin-bottom: 10px;
      }
      & + span {
        font-weight: 800;
      }
    }
  }
}