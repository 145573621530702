@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.subscribe {

  .container {
    max-width: 1274px;
  }

  .inner {
    border-radius: 20px;
    background-color: #A3D16C;
    @include size('padding-inline', 102, 40);
    padding-block: 42px;
    align-items: center;
    justify-content: space-between;

    @include rmax($xl) {
      justify-content: center;
      @include size('padding-inline', 30, 20);
    }
  }

  form {
    margin-bottom: 0;
    @include rmax($md) {
      width: 100%;
    }
  }

  .main-title {
    max-width: 380px;
    @include size('font-size', 50, 24);
    line-height: 1.1;
    @include rmax($xl) {
      max-width: 100%;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  input {
    width: 404px;
    padding: 18px 24px;
    border-radius: 10px;
    background-color: rgba(#fff, 0.5);

    @include rmax($md) {
      width: 100%;
    }
  }

  .btn {
    @include rmax($md) {
      max-width: 300px;
      width: 100%  !important;
      margin-inline: auto;
    }
  }
}