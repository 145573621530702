@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@import '../base/global-vars';

.py {
  &-xxl {
    @include size('padding-top', $indent-xxl, 20);
    @include size('padding-bottom', $indent-xxl, 20);

  }
  &-xl {
    @include size('padding-top', $indent-xl, 20);
    @include size('padding-bottom', $indent-xl, 20);
  }
  &-lg {
    @include size('padding-top', $indent-lg, 20);
    @include size('padding-bottom', $indent-lg, 20);
  }

  &-md {
    @include size('padding-top', $indent-md, 20);
    @include size('padding-bottom', $indent-md, 20);
  }

  &-sm {
    padding-top: #{$indent-sm}px;
    padding-bottom: #{$indent-sm}px;
    @include rmax($md) {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &-xs {
    padding-top: #{$indent-xs}px;
    padding-bottom: #{$indent-xs}px;
  }

  &-xxs {
    padding-top: #{$indent-xxs}px;
    padding-bottom: #{$indent-xxs}px;
  }

  &-6 {
    @include size('padding-top', 60, 20);
    @include size('padding-bottom', 60, 20);
  }

  &-5 {
    @include size('padding-top', 50, 20);
    @include size('padding-bottom', 50, 20);
  }

  &-4 {
    @include size('padding-top', 40, 20);
    @include size('padding-bottom', 40, 20);
  }

  &-3 {
    padding-top: 30px;
    padding-bottom: 30px;
    @include rmax($md) {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }

  &-2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &-1 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.px {
  &-xxl {
    @include size('padding-left', $indent-xxl, 20);
    @include size('padding-right', $indent-xxl, 20);
  }

  &-xl {
    @include size('padding-left', $indent-xl, 20);
    @include size('padding-right', $indent-xl, 20);
  }

  &-lg {
    @include size('padding-left', $indent-lg, 20);
    @include size('padding-right', $indent-lg, 20);
  }

  &-md {
    @include size('padding-left', $indent-md, 20);
    @include size('padding-right', $indent-md, 20);
  }

  &-sm {
    padding-left: #{$indent-sm}px;
    padding-right: #{$indent-sm}px;
    @include rmax($md) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &-xs {
    padding-left: #{$indent-xs}px;
    padding-right: #{$indent-xs}px;
  }

  &-xxs {
    padding-left: #{$indent-xxs}px;
    padding-right: #{$indent-xxs}px;
  }

  &-6 {
    @include size('padding-left', 60, 20);
    @include size('padding-right', 60, 20);
  }

  &-5 {
    @include size('padding-left', 50, 20);
    @include size('padding-right', 50, 20);
  }

  &-4 {
    @include size('padding-left', 40, 20);
    @include size('padding-right', 40, 20);
  }

  &-3 {
    padding-left: 30px;
    padding-right: 30px;
    @include rmax($md) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-2 {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-1 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.pb {
  &-xxl {
    @include size('padding-bottom', $indent-xxl, 20);
  }
  &-xl {
    @include size('padding-bottom', $indent-xl, 20);
  }

  &-lg {
    @include size('padding-bottom', $indent-lg, 20);
  }

  &-md {
    @include size('padding-bottom', $indent-md, 20);
  }

  &-sm {
    padding-bottom: #{$indent-sm}px;
    @include rmax($md) {
      padding-bottom: 16px;
    }
  }

  &-xs {
    padding-bottom: #{$indent-xs}px;
  }

  &-xxs {
    padding-bottom: #{$indent-xxs}px;
  }

  &-6 {
    @include size('padding-bottom', 60, 20);
  }

  &-5 {
    @include size('padding-bottom', 50, 20);
  }

  &-4 {
    @include size('padding-bottom', 40, 20);
  }

  &-3 {
    padding-bottom: 30px;
    @include rmax($md) {
      padding-bottom: 20px;
    }
  }

  &-2 {
    padding-bottom: 20px;
  }

  &-1 {
    padding-bottom: 10px;
  }
}

.pt {
  &-xxl {
    @include size('padding-top', $indent-xxl, 20);
  }
  &-xl {
    @include size('padding-top', $indent-xl, 20);
  }

  &-lg {
    @include size('padding-top', $indent-lg, 20);
  }

  &-md {
    @include size('padding-top', $indent-md, 20);
  }

  &-sm {
    padding-top: #{$indent-sm}px;
    @include rmax($md) {
      padding-top: 16px;
    }
  }

  &-xs {
    padding-top: #{$indent-xs}px;
  }

  &-xxs {
    padding-top: #{$indent-xxs}px;
  }

  &-6 {
    @include size('padding-top', 60, 20);
  }

  &-5 {
    @include size('padding-top', 50, 20);
  }

  &-4 {
    @include size('padding-top', 40, 20);
  }

  &-3 {
    padding-top: 30px;
    @include rmax($md) {
      padding-top: 20px;
    }
  }

  &-2 {
    padding-top: 20px;
  }

  &-1 {
    padding-top: 10px;
  }
}

.pa {
  &-xxl {
    @include size('padding', $indent-xxl, 20);
  }
  &-xl {
    @include size('padding', $indent-xl, 20);
  }
  &-lg {
    @include size('padding', $indent-lg, 20);
  }

  &-md {
    @include size('padding', $indent-md, 20);
  }

  &-sm {
    padding: #{$indent-sm}px;
    @include rmax($md) {
      padding: 16px;
    }
  }

  &-xs {
    padding: #{$indent-xs}px;
  }

  &-xxs {
    padding: #{$indent-xxs}px;
  }

  &-6 {
    @include size('padding', 60, 20);
  }

  &-5 {
    @include size('padding', 50, 20);
  }

  &-4 {
    @include size('padding', 40, 20);
  }

  &-3 {
    padding: 30px;
    @include rmax($md) {
      padding: 20px;
    }
  }

  &-2 {
    padding: 20px;
  }

  &-1 {
    padding: 10px;
  }

}

