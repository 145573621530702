.icon {
  &::before {
    content: '';
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  &-bg {
    &::before {
      width: var(--main-icon-size);
      height: var(--main-icon-size);
      aspect-ratio: 1 / 1;
    }

  }
}

.icon-mask {
  &::after {
    content: '';
    background-color: var(--c-accent);
    display: block;
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
  }

  &-bg {
    &::before {
      width: var(--main-icon-size);
      height: var(--main-icon-size);
      aspect-ratio: 1 / 1;
    }
  }
}
