.overflow {
  overflow: hidden;
}

.overflow-y {
  overflow-y: hidden;
}

.overflow-x {
  overflow-x: hidden;
}