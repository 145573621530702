.tabs {

  &__content {
    position: absolute;
    z-index: -100;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out;

    &.active {
      position: relative;
      z-index: 10;
      opacity: 1;
      visibility: visible;
    }
  }
}