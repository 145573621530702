@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;
@import '../base/global-vars';

.mb {

  &-xxl {
    @include size('margin-bottom', $indent-xxl, 20);
  }

  &-xl {
    @include size('margin-bottom', $indent-xl, 20);
  }

  &-lg {
    @include size('margin-bottom', $indent-lg, 20);
  }

  &-md {
    @include size('margin-bottom', $indent-md, 20);
  }

  &-sm {
    margin-bottom: #{$indent-sm}px;
    @include rmax($md) {
      margin-bottom: 16px;
    }
  }

  &-xs {
    margin-bottom: #{$indent-xs}px;
  }

  &-xxs {
    margin-bottom: #{$indent-xxs}px;
  }

  &-6 {
    @include size('margin-bottom', 60, 20)
  }

  &-5 {
    @include size('margin-bottom', 50, 20)
  }

  &-4 {
    @include size('margin-bottom', 40, 20)
  }

  &-3 {
    @include size('margin-bottom', 30, 15)
  }

  &-2 {
    margin-bottom: 20px;
  }

  &-1 {
    margin-bottom: 10px;
  }

  &-05 {
    margin-bottom: 5px;
  }
}

.mr {

  &-xxl {
    @include size('margin-right', $indent-xxl, 20);
  }

  &-xl {
    @include size('margin-right', $indent-xl, 20);
  }

  &-lg {
    @include size('margin-right', $indent-lg, 20);
  }

  &-md {
    @include size('margin-right', $indent-md, 20);
  }

  &-sm {
    margin-right: #{$indent-sm}px;
    @include rmax($md) {
      margin-right: 16px;
    }
  }

  &-xs {
    margin-right: #{$indent-xs}px;
  }

  &-xxs {
    margin-right: #{$indent-xxs}px;
  }


  &-6 {
    @include size('margin-right', 60, 20)
  }

  &-5 {
    @include size('margin-right', 50, 20)
  }

  &-4 {
    @include size('margin-right', 40, 20)
  }

  &-3 {
    @include size('margin-right', 30, 15)
  }

  &-2 {
    margin-right: 20px;
  }

  &-1 {
    margin-right: 10px;
  }

  &-05 {
    margin-right: 5px;
  }
}


.m-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

