@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.catalog-hero {
  // @include size('padding-top', 125, 50);
  // @include size('padding-bottom', 125, 50);

  .hero {

    &__inner {
      padding-top: 40px;
    }

    &__content {
      max-width: 540px;
    }


  }
  .text-pink {
    letter-spacing: 0.125em;
  }

  .hero__title {
    max-width: 450px;
    letter-spacing: -0.03em;
    margin-bottom: 18px;
  }

  .hero__text {
    letter-spacing: 0.02em;
  }
}