@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;
.contacts {
  @include size('padding-top', 86, 30);
  @include size('padding-bottom', 120, 30);

  &::before {
    background-color: #000;
    opacity: 0.6;
  }

  .container {
    position: relative;
    z-index: 10;
  }

  .main-title {
    font-size: 40px;
    margin-bottom: 36px;
  }

  .form-wrap {
    max-width: 574px;
    margin: 0 auto;
  }

  .form-group {
    margin-bottom: 16px;
  }

  .input {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    padding: 11px 16px;

    outline: 2px solid transparent;
    transition: 0.3s ease-in-out;

  &:focus {
    outline-color: var(--c-accent);
    outline-offset: 3px;
  }
  }

  .textarea {
    min-height: 156px;
  }

  .btn {
    padding: 14px 20px !important;
  }

}