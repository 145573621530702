@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.mission {

  .inner {
    align-items: center;
    @include rmax($lg) {
      flex-direction: column
    }
  }

  .image {
    border-radius: 10px;
    @include rmax($lg) {
      width: 100%;
      height: 250px;
    }
  }

  .main-title {
    @include size('font-size', 46, 30);
  }

  .main-text {
    @include size('font-size', 36, 20);
    max-width: 610px;
    @include rmax($lg) {
      max-width: 100%;
    }
  }
}