@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

$linkColor: var(--c-black);
$linkColorOnHover: var(--c-accent);
$linkFontSize: 18px;
$linkFontWeight: 800;
$gapBetweenLinks: 20px;
$outlineColor: var(--c-accent);

$makeResponsiveBreakpoint: $xl;

.link {
  display: block;
  font-size: $linkFontSize;
  font-weight: $linkFontWeight;
  white-space: nowrap;
  text-transform: capitalize;
  color: $linkColor;
  background-color: transparent;
  border-radius: 20px;
  outline: 2px solid transparent;
  padding: 0 5px;
  transition: 0.3s ease-in-out;

  &:focus {
    outline-color: rgba(#000, 0.5);
    outline-offset: 10px;
  }

  @include hover {
    color: $linkColorOnHover;
    // text-decoration: underline 1px solid black;
  }

  &.current {
    // color: $linkColorOnHover;
    // font-weight: bold;
    text-decoration: underline;
  }

  @include rmax($makeResponsiveBreakpoint) {
    font-size: 24px;
    padding: 10px 20px;
  }
}