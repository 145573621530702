@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.quality {

  .main-title {
    @include size('font-size', 34, 30);
    margin-bottom: 50px;
    @include rmax($lg) {
      margin-bottom: 30px;
    }
  }

  .inner {
    max-width: 1032px;
    margin-inline: auto;
    @include size('gap', 72, 25);
    @include rmax($md) {
      grid-template-columns: 1fr;
    }
  }
}