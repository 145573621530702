[class*="border"] {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-color {
  &-white {
    border-color: var(--c-white);
  }

  &-black {
    border-color: var(--c-black);
  }

  &-accent {
    border-color: var(--c-accent);;
  }

  &-light {
    border-color: var(--c-light);;
  }
}