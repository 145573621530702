@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.about-us {
  @include size('padding-bottom', 60, 30);


  .container {
    max-width: 1200px;
  }

  .first {
    @include rmax($lg) {
      flex-direction: column;
      margin-bottom: 30px;
    }

    .content {
      margin-bottom: 0;
    }
  }

  .second {
    @include size('padding-bottom', 80, 30);
    @include rmax($lg) {
      flex-direction: column;
    }

    .text {
      font-weight: 800;
      max-width: 520px;

      @include rmax($lg) {
        max-width: 760px;
      }
    }
  }

  .list {
    max-width: 1020px;
    margin-inline: auto;
  }


  .card {
    max-width: 315px;

    @include rmax($md) {
      max-width: 600px;
      margin: 0 auto;
    }

    &__title {
      display: block;
      @include size('font-size', 48, 30);
      margin-bottom: 18px;
      font-weight: 800;
      line-height: 1.2;
      letter-spacing: -0.018em;
    }

    &__text {
      line-height: 1.35;
      font-style: 20px;
      font-weight: 800;
    }
  }
}