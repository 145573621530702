.lh {
  &-100 {
    line-height: 1;
  }
  &-110 {
    line-height: 1.1;
  }
  &-115 {
    line-height: 1.15;
  }
  &-120 {
    line-height: 1.2;
  }
  &-125 {
    line-height: 1.25;
  }
  &-130 {
    line-height: 1.3;
  }
  &-135 {
    line-height: 1.35;
  }
  &-140 {
    line-height: 1.4;
  }
  &-145 {
    line-height: 1.45;
  }
  &-150 {
    line-height: 1.5;
  }
  &-155 {
    line-height: 1.55;
  }
  &-160 {
    line-height: 1.6;
  }
  &-165 {
    line-height: 1.65;
  }
  &-170 {
    line-height: 1.70;
  }
  &-175 {
    line-height: 1.75;
  }
}