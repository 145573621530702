@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

$headerBg: transparent;
$headerIndents: 20px;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  margin: 0 auto;
  padding: $headerIndents 0;
  width: 100%;
  background-color: $headerBg;
  transition-property: transform, padding, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  .container {
    max-width: 1430px;
  }

  &.scroll {
    .nav {
      box-shadow: 0 0 60px 3px rgba(#000, 0.25);
    }

  }


  &.hide {
    transform: translateY(calc(-100% - 30px));
  }
}