.overlay {
  position: relative;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  &-linear {
    &::before {
      background-image: var(--dark-gradient);
    }
  }

  &-radial {
    &::before {
      background-image: var(--radial-gradient);
    }
  }

  &-dark {
    &::before {
      background-color: var(--c-dark);
    }
  }

  &-primary {
    &::before {
      background-color: var(--c-primary);
    }
  }

  &-accent {
    &::before {
      background-color: var(--c-accent);
    }
  }

  &-op {
    &-20 {
      &::before {
        opacity: 0.2;
      }
    }
    &-40 {
      &::before {
        opacity: 0.2;
      }
    }
    &-60 {
      &::before {
        opacity: 0.2;
      }
    }
    &-80 {
      &::before {
        opacity: 0.8;
      }
    }
  }

  &-white {
    &::before {
      background-color: #fff;
    }
  }

  &-black {
    &::before {
      background-color: #000;
    }
  }
}
