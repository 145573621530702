@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.our-products {
  @include size('padding-top', 73, 30);

  .text {
    .color-accent {
      font-weight: 800;
    }

    @include size('margin-bottom', 92, 30);
  }

  .list {

    display: block;
    column-count: 2;
    column-gap: 80px;
    @include size('margin-bottom', 92, 30);

    @include rmax($lg) {
      column-count: unset;
    }

    & > li {
      break-inside: avoid;
      @include rmax($lg) {
        margin-bottom: 30px !important;
      }
      &:first-child,
      &:nth-child(3) {
        @include size('margin-bottom', 60, 20);
        @include rmax($lg) {
          margin-bottom: 0;
        }
      }

    }

  }

  .card {
    h3 {
      font-weight: 800;
      letter-spacing: -0.02em;
    }

    &__image {
      @include rmax($lg) {
        width: 100%;
        height: 250px;
      }
    }

  }

  & + .figure-decor {
    height: 100px;
    width: 100%;
    background-image: url('../assets/images/icons/figures.svg');
  }
}