@use "./../../scss/utils/functions/"as*;
@use "./../../scss/utils/mixins/"as*;

.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    height: 100vh;
    height: 100svh;

    @include rhmin(1081) {
      height: 1080px;
    }
  }

  &__content {
    position: relative;
    z-index: 10;
    max-width: 100%;
    background-color: rgba(#fff, 0.7);
  }

  .text-pink {
    margin-bottom: 20px;
  }

  &__title {
    font-weight: 800;
  }

  &__text {
    font-weight: 800;
  }


}