@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;
.explore {
  // padding-top: ;
  // @include size('padding-top', 165, 60);
  display: flex;
  flex-direction: column;
  align-items: center;

  &::before {
    @include size('padding-top', 80, 20);
    @include size('padding-bottom', 80, 20);
    width: 314px;
    height: 8px;
  }


  .main-text {
    max-width: 950px;
    margin: 0 auto;
    font-weight: 800;
    letter-spacing: 0.02em;
    font-size: 26px;


    .fw-300 {
      font-size: 30px;
    }

    p {
      &:not(:last-child) {
        margin-bottom: 35px;
      }
    }
  }

  .slides {
    max-width: 1920px;
    width: 100%;
    margin-bottom: 40px;
    padding-top: 10px;
    @include rmin(1920) {
      max-width: 100%;
    }
    img {
      max-width: unset;
      max-height: 720px;
      margin-inline: auto;
      @include rmax($lg) {
        max-height: 400px;
        max-width: 200%;
      }
    }
    overflow-x: hidden;
  }

  & + .decor {
    height: 45px;
    width: 100%;
    background-image: url('../assets/images/icons/figures.svg');
    background-size: 100%;
    background-repeat: repeat-x;
  }
}