@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.highlights {

  .main-text {
    text-align: center;
    letter-spacing: -0.035em;
    @include size('margin-bottom', 75, 30)
  }

  .list {
    max-width: 942px;
    margin: 0 auto;
    @include size('column-gap', 142, 20);
    @include size('row-gap', 60, 20);
  }

  .card {

    &__title {
      max-width: 250px;
      line-height: 1.55;
      &::before {
        min-width: 70px;
        height: 70px;
        border-radius: 15px;
        background-size: 32px;
      }

      @include rmax($md) {
        align-items: center;
        max-width: 100%;
      }

      // br {
      //   @include rmax($sm) {
      //     display: none;
      //   }
      // }
    }

    &__text {
      font-weight: 800;
    }
  }
}