@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.nav-menu {
  padding-right: 20px;
  @include rmax($xl) {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;
    padding-right: 0;
  }
  @include rhmax(600) {
    overflow-y: scroll;
  }
}