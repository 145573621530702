@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.home-hero {
  // @include size('padding-top', 125, 50);
  // @include size('padding-bottom', 125, 50);
  .hero__inner {
    padding-top: 45px;
  }

  .hero__content {
    max-width: 580px;
    background-color: rgba(#fff, 0.7);
  }

  .text-pink {
    letter-spacing: 0.125em;
  }

  .hero__title {
    letter-spacing: -0.03em;
    margin-bottom: 18px;
  }

  .hero__text {
    letter-spacing: 0.02em;
  }
}