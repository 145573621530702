@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.our-best {
  @include size('padding-top', 115, 30);
  .main-title {
    @include size('font-size', 46, 30);
    letter-spacing: 0.025em;
  }

  .inner {
    gap: 40px;
    max-width: 1130px;
    margin: 0 auto;
    @include rmax($lg) {
      gap: 20px;
    }
  }

  .card {
    max-width: 320px;
    margin: 0 auto;
    @include size('font-size', 22, 18);

    @include rmax($lg) {
      max-width: 450px;
    }

    &__title {
      font-weight: 300;
      @include size('font-size', 36, 24);
    }

    &__image {
      @include size('border-radius', 100, 20);
      @include rmax($lg) {
        width: 100%;
        height: 250px;
      }
    }

    &__subtitle {
      font-weight: 800;
      margin-bottom: 24px;
    }

    &__text {
      @include size('font-size', 20, 16);
      font-family: var(--font-family-secondary);
      font-weight: 500;
      line-height: 1.4;
    }

    br {
      @include rmax($lg) {
        display: none;
      }
    }
  }
}