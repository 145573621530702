@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.flex {
  display: flex;


  &.col-2 {
    & > * {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0%;
      width: 48%;
    }

    &.col-1 {
      &-sm {
        @include rmax($sm) {
        flex-wrap: wrap;
        & > * {


            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            width: 100% !important;
          }
        }
      }
      &-md {
        @include rmax($md) {
          flex-wrap: wrap;
          & > * {


              flex-grow: 1;
              flex-shrink: 0;
              flex-basis: auto;
              width: 100%;
            }
          }
      }
    }

  }

  &.col-3 {
    & > * {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: auto;
      width: 31%;
    }

    &.col-2-xl {
      @include rmax($xl) {
        flex-wrap: wrap;

        & > * {
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: auto;
          width: 48% !important;
        }
      }
    }

    &.col-2-lg {
      @include rmax($lg) {
        flex-wrap: wrap;

        & > * {
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: auto;
          width: 48% !important;
        }
      }



      &.col-1-md {
        @include rmax($md) {
          flex-wrap: wrap;

          & > * {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            width: 100% !important;
          }
        }
      }

    }

    &.col-1 {
      &-lg {
        @include rmax($lg) {
        flex-wrap: wrap;
        & > * {


            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            width: 100%;
          }
        }
      }
      &-md {
        @include rmax($md) {
        flex-wrap: wrap;
        & > * {


            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            width: 100% !important;
          }
        }
      }
      &-sm {
        @include rmax($sm) {
        flex-wrap: wrap;
        & > * {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: auto;
            width: 100% !important;
          }
        }
      }
    }


  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-col {
  flex-direction: column;
}

.flex-r-reverse {
  flex-direction: row-reverse;
}

.flex-c-reverse {
  flex-direction: column-reverse;
}

.flex-grow {
  flex: 1 0 auto;
}

.align {
  &-center {
    align-items: center;

    &-lg {
      @include rmax($lg) {
        align-items: center;
      }
    }
  }

  &-stretch {
    align-items: stretch;
  }

  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }
}

[class^="align"] {
  &.align-center {
    &-lg {
      @include rmax($lg) {
        align-items: center;
      }
    }

    &-md {
      @include rmax($md) {
        align-items: center;
      }
    }

    &-sm {
      @include rmax($md) {
        align-items: center;
      }
    }
  }
}

.justify {
  &-between {
    justify-content: space-between;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }
}

[class*="justify"] {
  &.justify-center {
    &-lg {
      @include rmax($lg) {
        justify-content: center;
      }
    }
    &-md {
      @include rmax($md) {
        justify-content: center;
      }
    }
    &-sm {
      @include rmax($sm) {
        justify-content: center;
      }
    }
    &-xs {
      @include rmax($xs) {
        justify-content: center;
      }
    }
  }
}