.op {
  &-80 {
    opacity: 0.8;
  }
  &-70 {
    opacity: 0.7;
  }
  &-60 {
    opacity: 0.6;
  }
  &-50 {
    opacity: 0.5;
  }
  &-40 {
    opacity: 0.4;
  }
  &-20 {
    opacity: 0.2;
  }
}