/* stylelint-disable declaration-no-important */
@use "../../../../scss/utils/functions"as*;
@use "../../../../scss/utils/mixins"as*;

// settings

$backgroundColor: var(--c-white);
$bgOnHover: var(--c-accent);

$textColor: var(--c-black);
$textOnHover: var(--c-white);

$borderRadius: 8px;
$borderColor: var(--c-accent);
$borderColorOnHover: var(--c-accent);

$fontWeigth: var(--fw-800);
$paddingY: 20px;
$paddingX: 48px;

$fontSize: 18px;
$letterSpacing: 0.02em;
$lineHeight: 1.1;

$borderWidth: 1px;

$outlineWidth: 2px;
$outlineColor: var(--c-accent);


*[class].btn {
  display: block;
  width: fit-content;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  font: inherit ;
  font-weight: $fontWeigth;
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
  white-space: nowrap;
  text-align: center;
  color: $textColor;
  border: $borderWidth solid $borderColor;
  background-color: $backgroundColor;
  outline: $outlineWidth solid transparent;
  // align-self: center;
  transition-property: background-color, color, border-color, outline;
  transition-duration: 0.2s;

  &:focus {
    outline-color: $outlineColor;
    outline-offset: 3px;
  }

  @include hover {
    border-color: $borderColorOnHover;
    color: $textOnHover !important ;
    background-color: $bgOnHover;
  }

  &-black {
    background-color: #000;
    color: #fff;
    border-color: #000;
  }

  &-sm {
    padding: 8px 25px;
  }


}


button.formsapp-popup-button {
  color: $textColor !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  &.btn-black {
    color: var(--c-white) !important;
  }
}