@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.no-hover {
  pointer-events: none;
}

.img-cover {
  object-fit: cover;
}

.img-contain {
  object-fit: contain;
}

.with-bg {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}